import request from "./index";

export function getMenusByUserId(id) {
  return request({
    url: `/user/${id}/action`,
    method: "get",
    params: {
      sort: "sequence,asc",
    },
  });
}

export function getAllMenus(params) {
  return request({
    url: "/action",
    method: "get",
    params: {
      ...params,
      sort: "sequence,asc",
    },
  });
}

export function getMenuList(params) {
  return request({
    url: "/actions",
    method: "get",
    params,
  });
}

export function addMenu(data) {
  return request({
    url: "/action",
    method: "post",
    data,
  });
}

export function editMenu(data) {
  return request({
    url: "/action/" + data.id,
    method: "patch",
    data,
  });
}

export function deleteMenu(id) {
  return request({
    url: "/action/" + id,
    method: "delete",
  });
}
