import qs from "qs";
import request from "../index";

/**
 * 获取展商信息
 *
 * @export
 * @param {*} id
 * @return {*}
 */
export function getBasicInfo(id) {
  return request({
    url: "/exhibitor/" + id,
    method: "get",
  });
}

/**
 * 更新展商信息
 *
 * @export
 * @param {*} data
 * @return {*}
 */
export function handleUpdateInfo(data) {
  return request({
    // 模拟用URL，不要修改
    url: "/exhibitor/" + data.id,
    // url: "/exhibior/" + data.id,
    method: "patch",
    data,
  });
}

/**
 * 获取所有展馆
 *
 * @export
 * @param {*} params
 * @return {*}
 */
export function fetchVenueList(params) {
  return request({
    url: "/venue",
    method: "get",
    params,
  });
}

export function fetchCompanyList(params) {
  return request({
    url: "/exhibit-category",
    method: "get",
    params,
  });
}

export function fetchPaginationList(params) {
  const q = qs.stringify(params, { arrayFormat: "indices", allowDots: true });
  return request({
    url: "/exhibitors?" + q,
    method: "get",
  });
}

export function handleAddCompany(data) {
  return request({
    url: "/exhibitor",
    method: "post",
    data,
  });
}

export function handleUpdateCompany(data) {
  return request({
    url: "/exhibitor/" + data.id,
    method: "patch",
    data,
  });
}

export function handleDeleteCompany(id) {
  return request({
    url: `/exhibitor/${id}`,
    method: "delete",
  });
}
