import { usePermissionStore } from "@/stores/permission";

export default {
  mounted(el, binding) {
    const store = usePermissionStore();
    const permissions = store.permissions;
    const { value } = binding;
    // const hasPermissions = true;

    const hasPermissions = value.some((key) => permissions.indexOf(key) > -1);

    if (!hasPermissions) {
      el.parentNode && el.parentNode.removeChild(el);
    }
  },
};
