<template>
  <div class="side-menu" :style="sideMenuStyle">
    <a-spin :spinning="spinning" style="min-height: 300px">
      <a-menu
        mode="inline"
        v-model:selectedKeys="selectedKeys"
        :open-keys="openKeys"
        @openChange="onOpenChange"
      >
        <layout-menu
          v-for="route in sideMenus"
          :key="route.id"
          :item="route"
        ></layout-menu> </a-menu
    ></a-spin>
  </div>
</template>

<script>
import { reactive, toRefs, computed, ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";

import { usePermissionStore } from "@/stores/permission";
// import { useSettingsStore } from "@/stores/settings";

import LayoutMenu from "./components/LayoutMenu.vue";

export default {
  components: {
    LayoutMenu,
  },
  setup() {
    const route = useRoute();
    const store = usePermissionStore();
    // const settingsStore = useSettingsStore();
    const sideMenuStyle = ref({});
    const spinning = ref(false);
    const menus = computed(() => store.menus);
    const sideMenus = ref([]);
    const state = reactive({
      openKeys: [],
      selectedKeys: [],
    });

    const getKeys = (key) => {
      if (key) {
        const item = store.routes.find((item) => item.id == key);
        if (item.superiorId) {
          return [item.id].concat(getKeys(item.superiorId));
        }
        return [item.id];
      } else {
        return [];
      }
    };

    const onOpenChange = (openKeys) => {
      if (openKeys.length == 0) {
        state.openKeys = [];
      } else {
        const latestOpenKey = openKeys[openKeys.length - 1];
        if (state.openKeys.includes(latestOpenKey)) {
          state.openKeys.shift();
        } else {
          const keys = getKeys(latestOpenKey);
          state.openKeys = keys;
          sessionStorage.setItem("openKeys", keys);
        }
      }
    };

    const initKeys = () => {
      const pid = route.meta.pid;
      const keys = getKeys(pid);
      state.openKeys = [...keys];
      state.selectedKeys = sessionStorage.getItem("selectedKeys")
        ? sessionStorage.getItem("selectedKeys").split(",")
        : [];
    };

    const fetchSideMenus = () => {
      console.log(menus.value);
      const routePath = route.path;
      console.log(routePath);
      const item = menus.value.find((menu) => routePath.startsWith(menu.path));
      console.log(item);
      console.log(item.children);
      sideMenus.value = item.children.filter((item) => {
        return item.actionKey.indexOf("detail") < 0; //hebang 20231006 如果是详情页面，则认为不作为菜单
      });
    };

    onMounted(() => {
      initKeys();
    });

    watch(
      () => state.selectedKeys,
      (keys) => {
        sessionStorage.setItem("selectedKeys", keys);
      }
    );

    watch(
      () => route.path,
      () => {
        if (route.meta.id === "776573335780855808") {
          state.selectedKeys = ["776233184793858048"]; //20231018 hebang 展商详情自动选定展商管理菜单高亮
        } else {
          state.selectedKeys = [route.meta.id];
          const pid = route.meta.pid;
          const keys = getKeys(pid);
          state.openKeys = [...keys];
          fetchSideMenus();
        }
      },
      {
        immediate: true,
      }
    );

    // watch(
    //   () => settingsStore.theme,
    //   (theme) => {
    //     const {
    //       asideBackgroundType,
    //       asideBackgroundImage,
    //       asideBackgroundColor,
    //     } = theme;
    //     if (asideBackgroundType == "image") {
    //       sideMenuStyle.value = {
    //         background: `url(${asideBackgroundImage}) no-repeat !important`,
    //         "background-size": "cover !important",
    //       };
    //     } else {
    //       sideMenuStyle.value = {
    //         background: `${asideBackgroundColor} !important`,
    //       };
    //     }
    //   },
    //   {
    //     immediate: true,
    //   }
    // );

    return {
      ...toRefs(state),
      sideMenus,
      spinning,
      menus,
      sideMenuStyle,
      onOpenChange,
    };
  },
};
</script>

<style lang="less" scoped>
.side-menu {
  position: fixed;
  padding: 20px 12px;
  box-sizing: border-box;
}

::v-deep {
  .ant-menu {
    .ant-menu-title-content {
      color: #575a7c;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
    }
  }
}

.side-menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.side-menu .ant-menu-item:active {
  background: #e0effb;
}
</style>
