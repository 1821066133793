<template>
  <div class="page-title">
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    const title = ref();

    watch(
      () => route.path,
      () => {
        title.value = route.name;
      },
      {
        immediate: true,
      }
    );

    return {
      title,
    };
  },
};
</script>

<style lang="less" scoped>
.page-title {
  height: 64px;
  background: #ffffff;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
  position: relative;
  padding: 0 24px;

  // margin-bottom: 16px;
  // &::before {
  //   position: absolute;
  //   display: block;
  //   content: "";
  //   width: 4px;
  //   height: 24px;
  //   background: #158fff;
  //   top: 12px;
  //   left: 0;
  // }
  .title {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #374561;
    line-height: 64px;
    text-align: left;
  }
}
</style>
