<template>
  <a-config-provider :locale="locale" :autoInsertSpaceInButton="false">
    <template #renderEmpty>
      <div style="text-align: center; margin-top: 50px">
        <img
          src="../src/assets/images/empty-data.png"
          style="max-width: 100%"
        />
      </div>
    </template>
    <router-view />
  </a-config-provider>
</template>

<script setup>
import { watch, onMounted } from "vue";
// import { useStore } from "vuex";
import { useRoute } from "vue-router";

import zhCN from "ant-design-vue/es/locale/zh_CN";
const locale = zhCN;
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");

const route = useRoute();

const getIp = () => {
  fetch("https://api.ipify.org/")
    .then((res) => res.text())
    .then((data) => {
      console.log(data);
      sessionStorage.setItem("ip", data);
    });
};

watch(
  () => route.name,
  () => {
    if (route.name == "login") {
      document.documentElement.classList.remove("light-form-wrapper");
    } else {
      document.documentElement.classList.add("light-form-wrapper");
    }
    // isLogin.value = route.name == "login";
  }
);

watch(
  () => route.path,
  (params) => {
    console.log(params);
  }
);

onMounted(() => {
  getIp();
  // loadRoutes();
});
</script>

<style lang="less"></style>
