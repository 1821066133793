export default {
  mounted(el, binding) {
    const { value } = binding;
    console.log(value);

    if (!value) {
      el.style.visibility = "hidden";
    }
  },
};
